

















































































































import { Component, Mixins } from "vue-property-decorator";
import { base_class_pantalla } from "@/shared/classes/base-class-pantallas";
import { RouterNames } from "@/router/routernames";
import dashboardModule from "@/store/modules/dashboard-module";
import { dashboard } from "@/shared/dtos/dashboard";
import preguntasModule from "@/store/modules/preguntas-module";
@Component({
  components: {
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
    ConfigDashboardLimits: () =>
      import("@/views/configuracion/dashboards/config_dashboards_limits.vue"),
    ConfigEncuestasLista: () =>
      import("@/views/configuracion/encuestas/config_encuestas_lista.vue"),
  },
})
export default class config_encuestas_formulario extends Mixins(
  base_class_pantalla
) {
  public tab: any = null;
  created() {
    this.nombre_pantalla = "Dashboard";
    this.toolbar_texto_editar = "Editar dashboard";
    this.toolbar_texto_crear = "Crear nueva dashboard";
    this.es_edicion = Number.parseInt(this.$route.params.id) > -1;
    if (this.es_edicion) {
      dashboardModule.getdashboard(Number.parseInt(this.$route.params.id));
    } else {
      dashboardModule.onGetdashboard(new dashboard());
    }
  }
  public get datapregunta() {
    return dashboardModule.dashboard;
  }
  
  public cargar() {
    preguntasModule.getpreguntasstoList().then(() => {
      preguntasModule.onGetpreguntasstoList(
        preguntasModule.preguntas_list.filter(
          (x) => x.id_dashboard === this.datapregunta.id
        )
      );
    });
  }

  public submit() {
    if (this.es_edicion) {
      dashboardModule
        .modificardashboard(dashboardModule.dashboard)
        .then(() => this.toList());
    } else {
      dashboardModule
        .guardardashboard(dashboardModule.dashboard)
        .then(() => this.toList());
    }
  }
  public eliminar() {
    dashboardModule
      .eliminardashboard(dashboardModule.dashboard)
      .then(() => this.toList());
  }
  public toList() {
    this.$router.push({ name: RouterNames.dashboardslista });
  }
}
